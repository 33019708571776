<template>
  <div class="container">
    <div class="top-title">
      <div class="title-page">Profil</div>
      <img src="@/assets/logo_agi.png" alt="" v-show="role == 13" class="bagi" />
    </div>
    <div class="wrap-content">
      <div class="form-group">
        <span>Full Name</span>
        <span>:</span>
        <span>{{ dataProfil.fullname }}</span>
      </div>
      <div class="form-group">
        <span>Email</span>
        <span>:</span>
        <span>{{ dataProfil.email }}</span>
      </div>
      <div class="form-group">
        <span>Phone Number</span>
        <span>:</span>
        <span>{{ dataProfil.phonenumber }}</span>
      </div>
      <div class="form-group">
        <span>username</span>
        <span>:</span>
        <span>{{ dataProfil.username }}</span>
      </div>
      <div class="form-group">
        <span>Status</span>
        <span>:</span>
        <span>{{ dataProfil.status }}</span>
      </div>

      <div>
        <button class="btn btn-primer mt-4" @click="openModal">Change Password</button>
        <b-modal ref="modal-change" title="Change Password">
          <div class="form-group">
            <label for="">Password</label>
            <div>
              <input :type="typePassword" placeholder="input current password" class="form-input" v-model="$v.form.password.$model" />
              <b-icon v-if="typePassword === 'password'" icon="eye-fill" class="icon-eye" @click="typePassword = 'text'"></b-icon>
              <b-icon v-else icon="eye-slash-fill" class="icon-eye" @click="typePassword = 'password'"></b-icon>
              <div class="error" v-if="$v.form.password.$error">
                <span v-if="!$v.form.password.required">Password is required</span>
                <span v-if="!$v.form.password.minLength">Password min length 8 character</span>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="">New Password</label>
            <div>
              <input :type="typeNewPassword" placeholder="input new password" class="form-input" v-model="$v.form.new_password.$model" />
              <b-icon v-if="typeNewPassword === 'password'" icon="eye-fill" class="icon-eye" @click="typeNewPassword = 'text'"></b-icon>
              <b-icon v-else icon="eye-slash-fill" class="icon-eye" @click="typeNewPassword = 'password'"></b-icon>
              <div class="error" v-if="$v.form.new_password.$error">
                <span v-if="!$v.form.new_password.required">New Password is required</span>
                <span v-if="!$v.form.new_password.minLength">New Password min length 8 character</span>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="">Confirm Password</label>
            <div>
              <input :type="typeConfirmPassword" placeholder="input confirm password" class="form-input" v-model.trim="$v.form.confirm_password.$model" />
              <b-icon v-if="typeConfirmPassword === 'password'" icon="eye-fill" class="icon-eye" @click="typeConfirmPassword = 'text'"></b-icon>
              <b-icon v-else icon="eye-slash-fill" class="icon-eye" @click="typeConfirmPassword = 'password'"></b-icon>
              <div class="error" v-if="$v.form.confirm_password.$error">
                <div v-if="!$v.form.confirm_password.required">Confirm Password is required</div>
                <div v-if="!$v.form.confirm_password.minLength">Confirm Password min length 8 character</div>
                <div v-if="!$v.form.confirm_password.sameAsPassword">Confirm Password Doesn't Match</div>
              </div>
            </div>
          </div>
          <template #modal-footer>
            <button type="submit" class="btn btn-primer" @click="changePassword">
              <span v-if="!isLoading">Submit</span>
              <b-spinner v-else small></b-spinner>
            </button>
          </template>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required, minLength, sameAs } from "vuelidate/lib/validators";
import sha256 from "sha256";
export default {
  data() {
    return {
      dataProfil: [],
      role: "",
      typePassword: "password",
      typeNewPassword: "password",
      typeConfirmPassword: "password",
      isLoading: false,
      form: {
        password: "",
        new_password: "",
        confirm_password: "",
      },
    };
  },
  validations: {
    form: {
      password: {
        required,
        minLength: minLength(8),
      },
      new_password: {
        required,
        minLength: minLength(8),
      },
      confirm_password: {
        required,
        minLength: minLength(8),
        sameAsPassword: sameAs("new_password"),
      },
    },
  },
  mounted() {
    this.role = sessionStorage.getItem("role");
    axios.get("/v2/user").then((res) => {
      this.dataProfil = res.data.data.body;
    });
  },
  methods: {
    openModal() {
      this.form.password = "";
      this.form.new_password = "";
      this.form.confirm_password = "";
      this.$refs["modal-change"].show();
    },
    changePassword() {
      this.$v.form.$touch();
      if (this.$v.form.$invalid) {
        return;
      }
      this.isLoading = true;
      axios
        .put("/v2/user/edit_password", {
          password: sha256(this.form.password),
          new_password: sha256(this.form.new_password),
        })
        .then((res) => {
          if (res.data.data.status_code == "00") {
            this.$toast.success("Change password success", {
              position: "top-center",
              timeout: 2000,
              closeOnClick: true,
              pauseOnFocusLoss: false,
              pauseOnHover: false,
              showCloseButtonOnHover: false,
              hideProgressBar: false,
              closeButton: "button",
              icon: true,
              rtl: false,
            });
          }
        })
        .catch((error) => {
          this.isLoading = false;
          let err = error.response.data.data;
          if (err.status_code == "1003") {
            this.$toast.error(err.err_message, {
              position: "top-center",
              timeout: 2000,
              closeOnClick: true,
              pauseOnFocusLoss: false,
              pauseOnHover: false,
              showCloseButtonOnHover: false,
              hideProgressBar: false,
              closeButton: "button",
              icon: true,
              rtl: false,
            });
          }
        });
    },
  },
};
</script>
<style scoped>
.container {
  width: 95%;
  margin: 0 auto;
}

.wrap-content {
  box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 11%);
  padding: 2rem;
}

.title-page {
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 40px;
}

.form-group {
  display: flex;
  gap: 1rem;
  position: relative;
}

.form-group span:first-child {
  width: 7rem;
}

.aseets,
.turn-over,
.revenue,
.performa,
.rped {
  box-shadow: 0 4px 20px 1px rgb(0 0 0 / 6%), 0 1px 4px rgb(0 0 0 / 8%);
  padding: 1rem;
  width: 49%;
  border-radius: 5px;
}

.top-title {
  display: flex;
  justify-content: space-between;
}

.bagi {
  height: 30px;
}

.form-input {
  width: 100%;
  border-radius: 5px;
  border: none;
  padding: 8px 1rem;
  box-shadow: 0 1px 3px rgb(50 50 93 / 15%), 0 1px 0 rgb(0 0 0 / 2%);
}

.form-input:focus {
  border-color: var(--primary-color);
  outline: 0;
  box-shadow: 0 1px 3px rgb(50 50 93 / 15%), 0 1px 0 rgb(0 0 0 / 2%);
}

.form-group label {
  width: 13rem;
}

.form-group div {
  width: 100%;
}

.icon-eye {
  position: absolute;
  top: 0.8rem;
  right: 1rem;
  color: #707070;
  cursor: pointer;
}
</style>
